<template>
  <section
    class="badges__wrapper"
    :class="{ 'remove-badge-padding': noSideSpace, 'reverse-order': reverse }"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'BadgesComponent',
  props: {
    noSideSpace: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.badges__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.reverse-order {
    flex-direction: column-reverse;
  }

  @include breakpoint($from-large-tablet) {
    flex-direction: row;
    align-items: stretch;

    &.reverse-order {
      flex-direction: row-reverse;
    }

    &.remove-badge-padding {
      justify-content: flex-start;

      > :deep(.badge:first-child) {
        margin-left: 0;
      }

      > :deep(.badge:last-child) {
        margin-right: 0;
      }
    }
  }

  @include breakpoint($up-to-large-tablet) {
    &.remove-badge-padding {
      > :deep(.badge) {
        justify-content: flex-start;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
</style>
